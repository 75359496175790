import React, { useState } from "react";
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import "./Header.css";
import HomeIcon from '@material-ui/icons/Home';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import DuoIcon from '@material-ui/icons/Duo';
import QuizIcon from '@material-ui/icons/ContactSupportRounded';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';

const Header = ({id,
  password,
  firstname,
  lastname,
  city,
  standard,
  avatar,
  subscribed }) => {
  const [title, setTitle] = useState(firstname+" "+ lastname + "(CLASS "+standard.toUpperCase()+")");
  let quizLink =new Map([
    ["i","https://classi.vercel.app/"],
    ["ii","https://classii.vercel.app/"],
    ["iii","https://classiii.vercel.app/"],
    ["iv","https://classiv.vercel.app/"],
    ["v","https://classv.vercel.app/"],
    ["vi","https://classvi.vercel.app/"],
    ["vii","https://classvii.vercel.app/"],
    ["viii","https://classviii.vercel.app/"],
    ["ix","https://classix.vercel.app/"],
    ["x","https://classx.vercel.app/"],
    ["xi","https://classxi.vercel.app/"],
    ["xii","https://classxii.vercel.app/"],
    ["xiii","https://classxiii.vercel.app/"],
    ["Teacher","https://classxiii.vercel.app/"]
  ]);
  const quizurl =quizLink.get(standard);
  return (
    <header className='header'>
      <h1> <div className="header__peoplefinder">
      <Link to ={{
    pathname: "/accounts/home/", 
    state: { 
      id: id,
      password: password,
      firstname:firstname,
      lastname: lastname,
      city: city,
      standard: standard,
      avatar: avatar,
      subscribed: subscribed
    }
   }} >
 <HomeIcon /></Link>
      </div></h1>
   
      <h3>
      <div className="header__logout">
      <Link to="/accounts/login/" variant="body2"><PowerSettingsNewIcon /></Link>
      </div>
      <div className="header__peoplefinder">
      <Link to ={{
    pathname: "/accounts/profile", 
    state: { 
      id: id,
      password: password,
      firstname:firstname,
      lastname: lastname,
      city: city,
      standard: standard,
      avatar: avatar,
      subscribed: subscribed
    }
   }} >
 <PermContactCalendarIcon /></Link>
      </div>
      <div className="header__peoplefinder">
      <Link to ={{
    pathname: "/accounts/explore/", 
    state: { 
      id: id,
      password: password,
      firstname:firstname,
      lastname: lastname,
      city: city,
      standard: standard,
      avatar: avatar,
      subscribed: subscribed
    }
   }} >
 <PeopleAltIcon /></Link>
      </div>
      <div className="header__peoplefinder">
      <Link to ={{
    pathname: "/accounts/masterclass/", 
    state: { 
      id: id,
      password: password,
      firstname:firstname,
      lastname: lastname,
      city: city,
      standard: standard,
      avatar: avatar,
      subscribed: subscribed
    }
   }} >
 <LocalLibraryIcon /></Link>
      </div>
      <div className="header__peoplefinder">
      <Link to ={{
    pathname: "/accounts/mytutor/", 
    state: { 
      id: id,
      password: password,
      firstname:firstname,
      lastname: lastname,
      city: city,
      standard: standard,
      avatar: avatar,
      subscribed: subscribed
    }
   }} >
 <QuestionAnswerIcon /></Link>
      </div>
      <div className="header__peoplefinder">
      <Link to={{ pathname: quizurl }} target="_blank">
 <QuizIcon /></Link> 
      </div>
      <div className="header__peoplefinder">
      <Link to ={{
    pathname: "/join/liveclass/", 
    query:{standard: standard, student: title}
   }} >
 <DuoIcon /></Link> 
      </div>
      </h3>
    </header>
      
  )
} 

Header.defaultProps = {
  firstname: 'shikshamitr.in',
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
}

// CSS in JS
// const headingStyle = {
//   color: 'red',
//   backgroundColor: 'black',
// }

export default Header