import React, { useEffect, Fragment } from "react";
import { useLocation } from "react-router-dom";
import SignIn from './SignIn';
import './LiveClass.css';

const LiveClass = () => {
  const location = useLocation();
  const standard = location.query.standard;
  const student = location.query.student;
  const userName = student;
  // This sample app has been updated to use Meeting SDK credentials https://marketplace.zoom.us/docs/guides/build/sdk-app
  var sdkKey = 'eaTDPNVFQBGkyeW7OJ9ANw'
  var sdkSecret = 'yMj64gU2TFw3k1MW5r2n1eLpDfAjUzr5'
  var role = 0
  var userEmail = 'tvamevnarayan@gmail.com';
  let meetingIdMap =new Map([
    ["i","4973154659"],
    ["ii","4973154659"],
    ["iii","4973154659"],
    ["iv","4973154659"],
    ["v","4973154659"],
    ["vi","4973154659"],
    ["vii","4973154659"],
    ["viii","4973154659"],
    ["ix","4973154659"],
    ["x","4973154659"],
    ["xi","4973154659"],
    ["xii","4973154659"],
    ["xiii","4973154659"],
    ["Teacher","4973154659"]
  ]);
  let meetingPwMap =new Map([
    ["i","03iDVP"],
    ["ii","03iDVP"],
    ["iii","03iDVP"],
    ["iv","03iDVP"],
    ["v","03iDVP"],
    ["vi","03iDVP"],
    ["vii","03iDVP"],
    ["viii","03iDVP"],
    ["ix","03iDVP"],
    ["x","03iDVP"],
    ["xi","03iDVP"],
    ["xii","03iDVP"],
    ["xiii","03iDVP"],
    ["Teacher","03iDVP"]
  ]);
  const authEndpoint = "https://shikshamitr.in/auth"; // 
  const meetingNumber= meetingIdMap.get(standard); //"4973154659";//
  const passWord = meetingPwMap.get(standard); //"03iDVP";//
  const registrantToken = "";
  const zakToken = "";
  // pass in the registrant's token if your meeting or webinar requires registration. More info here:
  // Meetings: https://marketplace.zoom.us/docs/sdk/native-sdks/web/client-view/meetings#join-registered
  // Webinars: https://marketplace.zoom.us/docs/sdk/native-sdks/web/client-view/webinars#join-registered
  var leaveUrl = 'https://shikshamitr.in'

  useEffect(async () => {

    const { ZoomMtg } = await import("@zoom/meetingsdk");// import("@zoomus/websdk"); 
    ZoomMtg.setZoomJSLib('https://source.zoom.us/3.11.2/lib', '/av');
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareWebSDK();
    /*
    ZoomMtg.generateSDKSignature({
      meetingNumber: meetingNumber,
      role: role,
      sdkKey: sdkKey,
      sdkSecret: sdkSecret,
      success: function (signature) {
        ZoomMtg.init({
          leaveUrl: leaveUrl,
          success: function (data) {
            ZoomMtg.join({
              meetingNumber: meetingNumber,
              signature: signature.result,
              sdkKey: sdkKey,
              userName: student,
              userEmail: userEmail,
              passWord: passWord,
              tk: '',
              success: function () {
                //console.log("-- Joined --");
              },
              error: function (error) {
                console.log(error)

              }

            })
          },
          error: function (error) {
            console.log(error)
          }
        })
      },
      error: function (error) {
        console.log(error)
      }
    })

  }, []);
   */
    
    const getSignature = async () => {
      try {
        const req = await fetch(authEndpoint, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            meetingNumber: meetingNumber,
            role: role,
          }),
        });
        const res = await req.json()
        const signature = res.signature;
        startMeeting(signature)
      } catch (e) {
        console.log(e);
      }
    };

    function startMeeting(signature) {
      ZoomMtg.init({
        leaveUrl: leaveUrl,
        patchJsMedia: true,
        leaveOnPageUnload: true,
        success: (success) => {
          console.log(success);
          // can this be async?
          ZoomMtg.join({
            signature: signature,
            sdkKey: sdkKey,
            meetingNumber: meetingNumber,
            passWord: passWord,
            userName: userName,
            userEmail: userEmail,
            tk: registrantToken,
            zak: zakToken,
            success: (success) => {
              console.log(success);
            },
            error: (error) => {
              console.log(error);
            },
          });
        },
        error: (error) => {
          console.log(error);
        },
      });
    }
    getSignature();
  }, []);
  
  if (!standard) {
    return <SignIn />  
  }
  return (<Fragment>
    <link type="text/css" rel="stylesheet" href="https://source.zoom.us/2.9.0/css/bootstrap.css"></link>
    <link type="text/css" rel="stylesheet" href="https://source.zoom.us/2.9.0/css/react-select.css"></link>
  </Fragment>);
}
export default LiveClass;