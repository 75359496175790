import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './Chat.css';
import { FaMicrophone } from 'react-icons/fa';


function Chat() {
    const [question, setQuestion] = useState('');
    const [chatHistory, setChatHistory] = useState([]);
    const [isChatting, setIsChatting] = useState(false);
    const chatWindowRef = useRef(null);
    const [isListening, setIsListening] = useState(false);
    const [useVoiceResponse, setUseVoiceResponse] = useState(false);
    const [micActive, setMicActive] = useState(false);  // Track mic active state
    const micButtonRef = useRef(null);  // Reference to the mic button for toggling class
  
  
    // Function to get current time
    const getCurrentTime = () => {
      return new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };
  
     // Function to handle Speech-to-Text (User Voice Input)
     const startListening = () => {
      const recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
      recognition.lang = 'en-US';
      recognition.interimResults = false;  // Ensures that results are not interim, but final
      recognition.onstart = () => {
        setIsListening(true);  // Update state to indicate the system is listening
        setUseVoiceResponse(true);
        setMicActive(true); // Activate mic vibration effect when listening
        if (micButtonRef.current) {
          micButtonRef.current.classList.add('clicked');  // Add 'clicked' class when microphone is active
        }
      };
      recognition.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        setQuestion(transcript); // Set the recognized speech as input
        generateAnswer(transcript); // Automatically send the recognized speech as a question
      };
      recognition.onend = () => {
        setIsListening(false);  // Reset the listening state when recognition ends
        setMicActive(false); // Deactivate mic vibration when listening ends
        if (micButtonRef.current) {
          micButtonRef.current.classList.remove('clicked');  // Remove 'clicked' class when recognition ends
        }
      };
      recognition.onerror = (event) => {
        console.error("Speech recognition error", event);
        setIsListening(false);  // Reset the listening state if an error occurs
        setMicActive(false); // Deactivate mic vibration on error
        if (micButtonRef.current) {
          micButtonRef.current.classList.remove('clicked');  // Remove 'clicked' class on error
        }
      };
      recognition.start(); // Start listening
    };
  
  async function generateAnswer() {
    if (!question.trim()) return;
  
    const userMessage = { sender: 'You', text: question, time: getCurrentTime() };
    setChatHistory((prevHistory) => [...prevHistory, userMessage]);
    setQuestion('');
    setIsChatting(true);
  
    const formattedHistory = chatHistory.map(msg => `${msg.sender}: ${msg.text}`).join('\n');
    
    const prompt = `
    You are an AI Tutor, an empathetic, helpful, and respectful and highly knowledgeable Teacher for Physics, Chemistry, Mathematics and English subjects. You are currently talking to a student who is having some problem understanding a concept and is seeking help.Your primary function is to understand student's problem and current understanding of problem by asking probing questions based on your knowledge of subject. Your goal is to:
    1.Ensure to gather detailed problem description by asking follow up questions from user of each question.
	2.After finishing the problem collection, provide solution based on your knowledge of subject and problem. At the end of the conversation, summarize the conversation and create a summary from the conversation and send it as part of the last message itself.The summary should highlight the key points just like how a Class Teacher would do. Along with that, breakdown student's key problem along with current understanding point by point , each new pont should start from a new line. Send the last message in an organized way and should be arranged in a manner that student can see each point clearly and efficiently.
  
    Please follow these steps:
    - Responses should be clear and structured, not in paragraphs.
    - Ask one question at a time
    - Keep responses short, crisp, and to the point.
    - Use **bold** and **highlighted text** for key points to make it easier for the user to read.
    - Be empathetic and professional in tone, while remaining concise.
    -Do not give final summary again and again, just gave the summary at the last of the first problem explained.
    -Send the last message in an organized way and should be arranged in a manner that user can see each point clearly and efficiently.
    
    Here is the conversation history:
    ${formattedHistory}
  
    Now, here is the user's new input:
    ${question}
    
    `;
  
  
    try {
      const response = await axios({
        url: 'https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-flash:generateContent?key=AIzaSyACstLWxMQ3dNBUzibv6tX25A5uxdRrWzU',
        method: 'post',
        data: {
          contents: [{ parts: [{ text: prompt }] }],
        },
      });
  
      let botResponse = response.data.candidates[0].content.parts[0].text;
      // Remove any repeated "AI Tutor:" at the start of the response
      botResponse = botResponse.replace(/^AI Tutor[:,]? /i, '');
      botResponse = botResponse
        .replace(/\n\n/g, '\n- ') // Convert new lines into bullet points
        .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') // Ensure bold formatting remains intact
        .replace(/\b(age|gender|medical history|chief complaint|duration|severity|related symptoms|symptoms|possible diagnoses|care plan)\b/gi, '<strong>$1</strong>');
  

      const botMessage = { sender: 'AI Tutor', text: botResponse, time: getCurrentTime() };
      setChatHistory((prevHistory) => [...prevHistory, botMessage]);
      setIsChatting(false);
      if (useVoiceResponse) {
        speak(botResponse);
        setUseVoiceResponse(false); // Reset after speaking
      }
    } catch (error) {
      setChatHistory((prevHistory) => [
        ...prevHistory,
        { sender: 'AI Tutor', text: 'Error fetching response', time: getCurrentTime() },
      ]);
      console.error(error);
      setIsChatting(false);
    }
  }
  const speak = (text) => {
    speechSynthesis.cancel();  // Stop any ongoing speech
    setTimeout(() => {
    const utterance = new SpeechSynthesisUtterance(text.replace(/<\/?[^>]+(>|$)/g, "") .replace(/\*/g," "));
    utterance.lang = 'en-US';
   // Wait for voices to be loaded before selecting
   const loadVoices = () => {
    const voices = speechSynthesis.getVoices();
    const preferredVoices = voices.filter(voice =>
      voice.name.includes("Google UK English Female") || 
      voice.name.includes("Microsoft Zira") || 
      voice.name.includes("Microsoft Cortana")
    );
  
    utterance.voice = preferredVoices.length > 0 ? preferredVoices[0] :  voices.find(voice => voice.lang === 'en-US') || voices[0];    // Default to the first available voice if no female voice is found
    utterance.rate = 1.25;
    utterance.pitch = 1;  // Slightly increase pitch for a more natural female tone
    utterance.volume = 1;   // Full volume
    speechSynthesis.speak(utterance);
  
  };
   // If voices are not yet loaded, listen for the event
   if (speechSynthesis.getVoices().length === 0) {
    speechSynthesis.onvoiceschanged = loadVoices;
  } else {
    loadVoices();
  }
  }, 100); // Small delay ensures cancellation takes effect
  };
  
  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTo({ top: chatWindowRef.current.scrollHeight, behavior: 'smooth' });
    }
    speechSynthesis.cancel();
  }, [chatHistory]);
  
  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !isChatting) {
      event.preventDefault(); // Prevents new line in textarea
      generateAnswer();
    }
  };
  
  return (
    
    <div className="app-container">
        <h3>Welcome to AI Tutor - Your Personal Guide</h3>
       <div className="chat-container">
        <div className="chat-window" ref={chatWindowRef}>
          {chatHistory.map((msg, index) => (
            <div key={index} className={msg.sender === 'You' ? 'user-box' : 'bot-box'}>
              <strong>{msg.sender}:</strong>
              <div dangerouslySetInnerHTML={{ __html: msg.text.replace(/\n/g, '<br>') }} />
              <div className="timestamp">{msg.time}</div>
            </div>
          ))}
          <textarea
          className="chat-input"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          onKeyDown={handleKeyPress} // Handle Enter key
          placeholder="Please type your query here..."
        >
        </textarea>
        <button className={`mic-button ${micActive ? 'active' : ''}`}
          onClick={() =>{
            if (isListening) {
              setMicActive(false); // Deactivate mic when clicked
              if (micButtonRef.current) {
                micButtonRef.current.classList.remove('clicked');
              } 
          }
          else {
            startListening(); // Start speech recognition
          }
        }}
      >
           <FaMicrophone />
        </button>
          </div> 
      </div>
    </div>
  );
  }
  export default Chat; 
  